@import '../../../styles/variables';

.ScholarshipCard {
  width: 100%;
  max-width: 100%;
  padding: 1rem 3rem;
  margin-bottom: 2rem;
  background: #fff;
  border: 0;
  border-radius: 18px;
  box-shadow: 0 7px 35px rgba(#5a6169, 0.1);
  text-align: left;

  h4 {
    color: #212529;
    font-size: 32px !important;
    font-weight: 500;
  }

  @media (max-width: $mobile-width) {
    padding: 0.5rem 1rem;

    h4 {
      font-size: 24px !important;
    }
  }
}

.ScholarshipCard__award-deadline {
  margin: 0.75rem 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ScholarshipCard__bottom-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.ScholarshipCard__badge-row {
  margin: 0.75rem 0 0 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
}

.ScholarshipCard__badge {
  margin-right: 1rem;
  padding: 0.1rem 1.5rem;
  background-color: #061f3d;
  border-radius: 18px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    font-size: 20px;
    font-weight: 400;
    margin: 0;
  }

  @media (max-width: $mobile-width) {
    padding: 0.1rem 1rem;

    p {
      font-size: 12px;
    }
  }
}

.ScholarshipCard__badge--secondary {
  background: 0;
  border: solid 2px #5a6169;
  color: #5a6169;
}

.ScholarshipCard__badge-popup-container {
  position: relative;
  top: -0.75rem;
}

.ScholarshipCard__badge-popup {
  position: absolute;
  padding: 1rem;
  top: 0;
  background: #ffffff;
  border: solid 2px #5a6169;
  border-radius: 18px;
  color: #5a6169;
  display: inline-block;
}

.ScholarshipCard__deadline {
  color: #5a6169;
  font-size: 19px;
  font-weight: 700;
}

.ScholarshipCard__desc {
  color: #5a6169;
  font-size: 19px;
  font-weight: 500;
  margin: 1rem 0;

  @media (max-width: $mobile-width) {
    font-size: 16px;
  }
}

.ScholarshipCard__apply {
  padding: 0.25rem 4rem;
  margin: 0 0 1rem 0;
  background: #007bff;
  border-radius: 8px;
  color: #fff;
  font-size: 20px;
  display: inline-block;

  @media (max-width: $mobile-width) {
    padding: 0.25rem 2rem;
    font-size: 16px;
  }
}

// Modal specific styles
.ScholarshipCard__modal {
  min-width: 800px;
  border-radius: 18px;

  @media (max-width: 800px) {
    min-width: auto;
  }
}

.ScholarshipCard__modal--open {
  margin-bottom: 0;
}

.ScholarshipCard__modal-deadline {
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ScholarshipCard__deadline--modal {
  color: #5a6169;
  font-size: 19px;
  font-weight: 400;
}

.ScholarshipCard__deadline-label {
  color: #5a6169;
  font-size: 19px;
  font-weight: 700;
  margin-bottom: 0;
}
