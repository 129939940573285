@import '../../../styles/variables';

.RefinementList {
  margin: 0 1rem 1rem 0;
  padding: 0.5rem 1rem;
  background: #fff;
  border: solid #007bff 2px;
  border-radius: 8px;
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none;
  display: flex;
  align-items: center;

  p {
    color: #5c5c5c;
    font-family: 'Poppins';
    font-size: 15px;
    font-weight: 700;
    margin: 0 0.5rem 0 0;
  }

  @media (max-width: $mobile-width) {
    width: 90vw;
    padding: 0.25rem 1rem;
    justify-content: space-between;

    p {
      font-size: 12px;
    }
  }
}

.RefinementList--open {
  background: #007bff;

  p {
    color: #ffffff;
  }

  svg {
    color: #ffffff;
  }
}

.RefinementList__dropdown-container {
  position: relative;
  z-index: 5;
  top: -0.75rem;
  left: 0;
  background: #ffffff;
  border-radius: 15px;

  @media (max-width: $mobile-width) {
    top: -0.75rem;
  }
}

.RefinementList__dropdown {
  position: absolute;
  max-height: 500px;
  background: #ffffff;
  border: solid #007bff 2px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.RefinementList__search-wrapper {
  border-bottom: solid #007bff 2px;
}

.RefinementList__search {
  margin: 0.5rem 1rem;
  padding: 0.5rem 2rem;
  border: solid 2px #e6e8ed;
  border-radius: 25px;
}

.RefinementList__search-results {
  width: 100%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    color: #c9c7c7;
    font-family: 'Poppins';
  }
}

.RefinementList__search-result {
  width: 100%;
  background: #fff;
  border: 0;

  p {
    margin: 0.5rem 0.5rem;
    max-width: 256px;
    text-align: left;
  }
}

.RefinementList__search-result:hover {
  p {
    color: #007bff;
  }
}

.RefinementList__search-result--selected {
  p {
    color: #007bff;
    font-weight: 700;
  }
}

.RefinementList__separator {
  height: 2px;
  border: solid 1px #c9c7c7;
  margin: 0;
}
