#page_section {
  flex-basis: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

#page_section p {
  padding: 0 10px;
  margin: 0;
}

#housing_cards_section {
  width: 40vw;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

@media (max-width: 768px) {
  #housing_cards_section {
    width: 100%;
  }
}

.housing_card {
  width: 100%;
  flex-basis: 45%;
  border: 0.5px solid rgba(0, 0, 0, 0.5);
  border-radius: 0.3rem;
  overflow: hidden;
  margin: 0.3em;
  cursor: pointer;
}

@media (max-width: 1000px) {
  .housing_card {
    flex-basis: 90%;
  }
}

.housing_card:hover {
  border: 0.5px solid rgba(0, 0, 255, 0.5);
}

.housing_card_image {
  width: 100%;
  object-fit: cover;
  height: 10rem;
}

.housing_card_info {
  padding: 0.4rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.housing_card_info p {
  margin: 0;
  padding: 0;
}

.housing_info_header {
  padding-top: 6px;
  font-weight: 500;
  font-size: 21pt;
  color: black;
}

.housing_sub_text {
  color: #565656;
  font-size: 10pt;
  font-weight: 300;
}
