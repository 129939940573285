#gallery {
  width: max-content;
  height: 100%;
}

#gallery img {
  display: inline-block;
  height: 100%;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

#photo_section {
  height: 50vh;
  overflow-x: scroll;
  cursor: grab;
}

#photo_section::-webkit-scrollbar {
  background-color: #a1c6ff;
}

#photo_section::-webkit-scrollbar-thumb {
  background-color: #5297ff;
  border-radius: 10px;
  margin: 1px;
}

#back_button {
  color: #1264de;
  font-size: 1.2em;
  font-weight: bold;
  cursor: pointer;
  padding: 0.5em;
  display: flex;
  width: min-content;
  align-items: center;
  transition: all 0.2s linear;
}

#back_button svg {
  width: 1.2em;
  height: 1.2em;
  margin-left: 0.2em;
  transition: all 0.2s linear;
}

#back_button:hover {
  color: #5297ff;
}

#back_button:hover svg {
  transform: scale(1.1);
}

#back_button:hover svg path {
  fill: #5297ff;
  stroke: #5297ff;
}

#detail_info {
  margin: 1em 2em;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

#detail_info * {
  color: black;
}

#price {
  font-weight: 800;
  font-size: 2.7em;
}
#address {
  font-weight: 600;
  font-size: 1.6em;
}

#features {
  font-weight: 500;
  font-size: 1.4em;
  text-align: right;
}

#agent {
  color: #717171;
  font-size: 1em;
  font-weight: 400;
  text-align: right;
}

#map_section {
  height: 30vh;
  width: 100%;
  background-color: #a1c6ff;
}

#coming_soon {
  margin: 2em;
}

#coming_soon svg {
  width: 50%;
  display: block;
  margin: -40px auto;
}

#coming_soon h4 {
  font-size: 1.5em;
  font-weight: bold;
  text-align: center;
  color: black;
}
