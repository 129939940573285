#results_section {
  padding-top: 6.5em;
  display: flex;
  height: 84vh;
  overflow: hidden;
}

#results_toggle {
  position: fixed;
  bottom: 30px;
  background-color: #3aacff;
  left: 50%;
  transform: translate(-50%, 0);
  color: white;
  padding: 0.4em 0.5em;
  border-radius: 7px;
  cursor: pointer;
  font-size: 1.5em;
  font-weight: 500;
}

#toggle-contents {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
