#housing_map_section {
  background-color: rgba(0, 0, 255, 0.5);
  width: 60vw;
  height: 100%;
}

@media (max-width: 768px) {
  #housing_map_section {
    width: 100%;
  }
}

.map_container {
  height: 100%;
  width: 100%;
}

.marker {
  background-image: url('../../assets/housing/marker.svg');
  background-size: cover;
  width: 30px;
  height: 30px;
  cursor: pointer;
}
