.house_card {
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  background-color: #fff;
  display: flex;
  overflow: hidden;
  height: 120px;
  margin: 0.5em;
}

.house_image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.house_image {
  height: 100%;
  width: 10em;
}

.detail_section {
  width: 100%;
}

.detail_section * {
  color: black;
}
.address {
  font-size: 1.2em;
  font-weight: 500;
  margin: 0;
  padding: 0.1em 0.5em;
}

.beds_baths {
  font-size: 1em;
  font-weight: 500;
  margin: 0;
  padding: 0.1em 0.5em;
}

.price {
  font-size: 1.2em;
  font-weight: bold;
  margin: 0;
  padding: 0.1em 0.5em;
  text-align: right;
  width: 100%;
}

.description {
  font-size: 0.8em;
  font-weight: 400;
  margin: 0;
  padding: 0.1em 0.5em;
  height: 40px;
  overflow: scroll;
}

.contact {
  font-size: 1.2em;
  font-weight: bold;
  margin: 0;
  padding: 0.1em 0.5em;
  display: none;
}
