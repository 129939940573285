#main_dashboard {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
  min-height: 87vh;

  background-image: url('../../assets/dashboard/dashboard-background.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 3em;
}

.dashboard_section {
  height: min-content;
  max-width: 40em;
}

.dashboard_section .main_card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 1px -3px 12px rgba(0, 0, 0, 0.2);
  padding: 1em;
  margin: 0 1em 1em 1em;
  min-width: 20em;
}

.dashboard_section h2 {
  color: black;
  text-align: center;
}

.dashboard_section #featured_text {
  color: black;
  font-weight: 400;
  font-size: 1.2em;
  text-align: center;
}

.dashboard_section button {
  border: none;
  background-color: rgba(89, 146, 232, 1);
  color: white;
  border-radius: 5px;
  font-weight: 500;
  padding: 0.5em 1em;
  margin: 0 auto;
  display: block;
}

#questionaire_text {
  text-align: center;
  padding: 1em;
  font-size: 1.2em;
}
