.partner_header {
  color: black;
  text-align: center;
  font-size: 24pt;
  font-weight: 300;
  margin-bottom: 1.3em;
}

.partner_image {
  width: 50%;
  max-width: 40em;
  height: auto;
  margin: 0 auto;
  display: block;
}

.partner_description {
  padding: 1em 3em;
  color: black;
  text-align: center;
  font-size: 16pt;
  font-weight: 300;
}

.partner_cta {
  background-color: rgba(18, 100, 222, 0.7);
  color: white;
  padding: 0.7em 1em;
  border-radius: 5px;
  margin: 0 auto;
  display: block;
  width: 10em;
  text-align: center;
  font-size: 13pt;
  text-decoration: none;
  margin-bottom: 3em;
  transition: background-color 0.2s ease-in-out;
}

.partner_cta:hover {
  background-color: rgba(18, 100, 222, 0.9);
  color: white;
}

.inactive_partner_cta {
  pointer-events: none;
  background-color: rgba(18, 100, 222, 0.2);
  color: white;
  padding: 0.7em 1em;
  border-radius: 5px;
  margin: 0 auto;
  display: block;
  width: 10em;
  text-align: center;
  font-size: 13pt;
  text-decoration: none;
  margin-bottom: 3em;
}
