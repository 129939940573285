/*
  Contains universal styles that may be used anywhere in the project.
  You should be able to just use the CSS classes without any imports.
*/
@import './variables';

:root {
  --primary-color: #1264de;
  --secondary-color: #061f3d;
  padding-top: 100px;
}

body {
  margin: 0;
  font-family: 'Poppins', 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4 {
  color: var(--primary-color);
  font-family: 'Poppins', 'Roboto', sans-serif;
  font-weight: 700;
}

h5,
h6 {
  font-family: 'Poppins', 'Roboto', sans-serif;
  font-weight: 500;
}

p {
  font-family: 'Roboto';
}

a {
  color: var(--primary-color);
}

a:hover {
  color: var(--secondary-color);
  text-decoration: none;
}

// @media (max-width: $mobile-width) {
//   h1 {
//     font-size: 36px !important;
//   }

//   h2 {
//     font-size: 32px !important;
//   }

//   h3 {
//     font-size: 28px !important;
//   }

//   h4 {
//     font-size: 24px !important;
//   }

//   h5 {
//     font-size: 18px !important;
//   }

//   h6 {
//     font-size: 18px !important;
//   }

//   p {
//     font-size: 16px !important;
//   }
// }

/*
  Card styles
*/
.card {
  border: none;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.18);
  padding: 1rem;
  margin-bottom: 0.5rem;
}

.dashboard-card {
  border: none;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.18);
  padding: 1rem;
  margin-bottom: 0.5rem;
}

.dashboard-card:hover {
  background: var(--primary-color) !important;
  color: #fff;

  h1 {
    color: #fff;
  }

  h4 {
    color: #fff;
  }
}

.scholarship-card {
  position: relative;
}

/*
  Button styles
*/
/* Wrap buttons inside button-container to size button based on text-width*/
.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

button.pill-button {
  border: 2px solid var(--primary-color);
  font-family: 'Poppins', 'Roboto';
  font-weight: 700;
  padding: 1rem 3rem;
  margin: auto;
}

button.pill-button:active {
  border: 2px solid var(--secondary-color) !important;
  background: var(--secondary-color) !important;
}

button.rectangle-button {
  background: #fff;
  border: solid 2px;
  font-family: 'Poppins', 'Roboto';
  font-weight: 700;
  padding: 0.25rem 3rem;
}

button.rectangle-button-back {
  @extends .rectangle-button;
  border-color: var(--secondary-color);
  color: var(--secondary-color);
}

button.rectangle-button-next {
  @extends .rectangle-button;
  border-color: var(--primary-color);
  color: var(--primary-color);
}

button.rectangle-button-next:hover {
  @extends .rectangle-button;
  border-color: var(--primary-color);
  background: var(--primary-color);
}

button.rectangle-button-next:active {
  @extends .rectangle-button;
  border-color: var(--secondary-color);
  background: var(--secondary-color);
}

.single-line-button {
  white-space: nowrap;
}

.card-apply,
.modal-apply {
  position: absolute;
  right: 1rem;
}

.card-apply {
  bottom: 1rem;
}

.modal-apply {
  top: -0.5rem;
}

/*
  Form styles
*/

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
}

.form-header {
  background: none;
  border: none;

  h1 {
    color: var(--primary-color);
  }
}

.form-body {
  display: flex;
  flex-direction: column;
  margin: auto;

  input {
    height: 3.5rem;
    border: 2px solid rgba(0, 0, 0, 0.1);
    font-family: 'Poppins', 'Roboto';
    font-weight: 500;
    margin-bottom: 1rem;
  }

  label {
    color: var(--secondary-color);
    font-family: 'Poppins', 'Roboto';
    font-weight: 500;
  }
}

.form-dropdown-input {
  height: 3.5rem !important;
  border: 2px solid rgba(0, 0, 0, 0.1);
  font-family: 'Poppins', 'Roboto';
  font-weight: 500;
  margin-bottom: 1rem;
}

.form-button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/*
  File section styles
*/
.file-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.file-progress {
  // border: solid red;
  width: 80%;
  height: 2rem;
  border-radius: 15px;
  margin: 2rem 0;
}

.file-progress-bar {
  min-width: 1rem;
  height: 2rem;
  // border: solid green;
}

/*
  Signin/login option styles
*/
.signup-login-option {
  margin: 2rem auto;
  text-align: center;

  h5 {
    color: var(--primary-color);
    text-decoration: underline;
  }
}

/*
  Navbar Styles
*/

.wide-nav {
  @media (max-width: 1220px) {
    display: none;
  }
}

.mobile-nav {
  @media (min-width: 1221px) {
    display: none;
  }
}

/*
  BgCheck styles
*/

.address-grid {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  @media (max-width: 950px) {
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
  row-gap: 1rem;
  column-gap: 1rem;
  justify-items: left;
  align-items: left;
}

.address-from-to {
  border-radius: 15px;
  width: 100%;
  grid-row: 1 / span 1;
  grid-column: 1 / span 1;
  @media (max-width: 950px) {
    grid-row: 1 / span 1;
    grid-column: 1 / span 1;
  }
}

.address-street {
  grid-row: 1 / span 1;
  grid-column: 2 / span 1;
  border-radius: 15px;
  @media (max-width: 950px) {
    grid-row: 1 / span 1;
    grid-column: 2 / span 1;
  }
}

.address-line-2 {
  grid-row: 1 / span 1;
  grid-column: 3 / span 1;
  border-radius: 15px;
  @media (max-width: 950px) {
    grid-row: 2 / span 1;
    grid-column: 1 / span 1;
  }
}

.address-city {
  border-radius: 15px;
  grid-row: 2 / span 1;
  grid-column: 1 / span 1;
  @media (max-width: 950px) {
    grid-row: 2 / span 1;
    grid-column: 2 / span 1;
  }
}

.address-state {
  border-radius: 15px;
  grid-row: 2 / span 1;
  grid-column: 2 / span 1;
  @media (max-width: 950px) {
    grid-row: 3 / span 1;
    grid-column: 1 / span 1;
  }
}

.address-zip {
  border-radius: 15px;
  grid-row: 2 / span 1;
  grid-column: 3 / span 1;
  @media (max-width: 950px) {
    grid-row: 3 / span 1;
    grid-column: 2 / span 1;
  }
}

.id-grid {
  display: grid;
  grid-template-rows: 20px 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  @media (max-width: 950px) {
    grid-template-rows: 20px 1fr;
    grid-template-columns: 1fr 1fr;
  }
  row-gap: 1rem;
  column-gap: 1rem;
  justify-items: left;
  align-items: left;
}

.id-title {
  border-radius: 15px;
  grid-row: 1 / span 1;
  grid-column: 1 / span 1;
  text-align: left;
}

.id-license {
  border-radius: 15px;
  grid-row: 2 / span 1;
  grid-column: 1 / span 1;
  @media (max-width: 950px) {
    grid-row: 2 / span 1;
    grid-column: 1 / span 3;
    width: 100%;
  }
}

.id-ssn {
  border-radius: 15px;
  grid-row: 2 / span 1;
  grid-column: 2 / span 1;
  @media (max-width: 950px) {
    grid-row: 3 / span 1;
    grid-column: 1 / span 1;
  }
}

.id-dob {
  border-radius: 15px;
  width: 100%;
  grid-row: 2 / span 1;
  grid-column: 3 / span 1;
  @media (max-width: 950px) {
    grid-row: 3 / span 1;
    grid-column: 2 / span 1;
  }
}

/*
  Misc styles
*/

.bold {
  font-weight: 700;
}

.dark {
  color: (var(--secondary-color));
}

.m-2 {
  margin: 2;
}

.mr-025 {
  margin-right: 0.25rem;
}

.mtb-2,
.mt-2 {
  margin-top: 2rem;
}

.mtb-3,
.mt-3 {
  margin-top: 3rem;
}

.mb-3,
.mtb-3 {
  margin-bottom: 3rem;
}

.mtb-2 {
  margin-bottom: 2rem;
}

.mtb-05 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.showit {
  border: solid red 1px;
}
