#search_hero {
  background-image: url('../../assets/housing/search-hero.png');
  width: 100%;
  height: 87vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

#search_hero h1 {
  font-size: 3rem;
  font-weight: bold;
  color: white;
  text-align: center;
  padding-top: 30vh;
}

#search_bar {
  margin: 0 auto;
  display: flex;
  width: 40em;
  max-width: 75%;
  background-color: white;
  border-radius: 0.5rem;
  height: 5em;
  padding: 0.5em 0.9em;
  margin-top: 2em;
}

#search_bar button {
  background-color: transparent;
  border: none;
  padding: 0.5rem;
}

#search_bar img {
  width: 1.9rem;
  height: 1.9rem;
}

#search_bar input {
  width: 100%;
  border: none;
  border-radius: 0.5rem;
  font-size: 1.5rem;
  font-weight: bold;
  background-color: transparent;
  color: black;
  font-weight: 400;
}

#search_bar input::placeholder {
  color: #5a5a5a;
  font-weight: 400;
  transition: color 0.1s linear;
}

#search_bar input:focus::placeholder {
  color: transparent;
}

#search_bar input:focus {
  outline: none;
}

#partner_header {
  color: black;
  text-align: center;
  font-size: 36pt;
  padding: 0.9em 0;
  margin-bottom: 1em;
}
