@import '../../styles/variables';

.FindPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  -webkit-overflow-scrolling: touch;
  @media (max-width: $mobile-width) {
    h1,
    h5 {
      text-align: center;
    }
  }
}

.FindPage__title {
  font-size: 55px;
  font-weight: 700;
  margin-bottom: 1rem;
}

.FindPage__button-row {
  max-width: 800px !important;
  margin: 1rem 0 2rem 0;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.FindPage__button {
  padding: 0.25rem 4rem;
  margin: 0;
  background: #007bff;
  border: 0;
  border-radius: 8px;
  color: #fff;
  font-size: 20px;
  display: inline-block;
}
