#query_box {
  width: 100%;
  align-items: center;
  padding-left: 1.2em;
  justify-content: flex-start;
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.25);
  position: fixed;
  background-color: white;
  z-index: 1;
}

#query_box form {
  padding: 0;
  margin: 0;
}

#filter {
  font-weight: 400;
  color: #1264de;
  margin: 0;
  font-size: 1.5em;
}

#search_bar {
  display: flex;
  width: 25rem;
  max-width: 45vw;
  background-color: white;
  border-radius: 0.5rem;
  height: 4em;
  padding: 0.5em;

  margin: 1.1em 1em 1.1em 0;
  border: 1px solid rgba(0, 0, 0, 0.4);
}

#search_bar button {
  background-color: transparent;
  border: none;
  padding: 0.5rem;
}

#search_bar img {
  width: 1.9rem;
  height: 1.9rem;
}

#search_bar input {
  width: 100%;
  border: none;
  border-radius: 0.5rem;
  font-size: 1.5rem;
  font-weight: bold;
  background-color: transparent;
  color: black;
  font-weight: 400;
}

#search_bar input::placeholder {
  color: #9e9e9e;
  font-weight: 400;
  transition: color 0.1s linear;
}
#search_bar input:focus::placeholder {
  color: transparent;
}

#search_bar input:focus {
  outline: none;
}
