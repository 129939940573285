@import '../../../styles/variables';

.ScholarshipSearch__search-bar {
  input {
    padding: 0.5rem 2rem;
    border: solid 2px #e6e8ed;
    border-radius: 25px;
    font-weight: 500;
  }

  svg {
    z-index: -1;
  }
}

.ScholarshipSearch__filters {
  margin: 1rem 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.ScholarshipSearch__match-button {
  margin: 0 1rem 1rem 0;
  padding: 0.5rem 1rem;
  background: #fff;
  border: solid #007bff 2px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none;

  p {
    color: #5c5c5c;
    font-family: 'Poppins';
    font-size: 15px;
    font-weight: 700;
    margin: 0 0.5rem 0 0;
  }

  svg {
    color: #5c5c5c;
  }

  @media (max-width: $mobile-width) {
    width: 90vw;
    padding: 0.25rem 1rem;

    p {
      font-size: 12px;
    }
  }
}

.ScholarshipSearch__match-button--on {
  background: #007bff;

  p {
    color: #fff;
    font-family: 'Poppins';
    font-size: 15px;
    font-weight: 700;
  }

  svg {
    color: #fff;
  }
}

.ScholarshipSearch__pagination-wrapper {
  width: 100%;
  margin: 2rem 0;
  background: 0;
  border: 0;
}
